<template>

<!-- 校园管理  校级管理员 新增-->
  <div class="page">
    <div class="account-form">
      <el-form label-width="100px" :model="ruleForm" :rules="rules" ref="ruleForm" size="mini"
               class="account-ruleForm el-form">
        <el-form-item label="姓名：" prop="sysName">
          <el-input v-model="ruleForm.sysName" placeholder="请填写姓名"
                    maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机：" prop="phone">
          <el-input maxlength="11" v-model="ruleForm.phone" placeholder="请填写手机"></el-input>
        </el-form-item>
        <el-form-item>
          <div class="tipss">
            tips：创建账号后请将密码复制到其它区域保存，该系统为了保证账号安全，只可联系平台管理员修改，平台管理员微信：<span class="forestgreen">jintian5453</span>
          </div>
        </el-form-item>
        <FormButtonGroup @saveData="submitForm('ruleForm')" save-text="保存"></FormButtonGroup>
      </el-form>

    </div>
  </div>
</template>

<script>
import sysService from '@/globals/service/detection/Sys.js'
import storage from '@/globals/storage/index.js'
export default {
  data () {
    const checkPhone = (rule, value, callback) => {
      const reg = /^1[345789]\d{9}$/
      if (!reg.test(value)) {
        callback(new Error('请输入11位手机号'))
      } else {
        callback()
      }
    }

    return {
      fullscreenLoading: false,
      checked: false,
      roleTypeOptions: [
        {
          value: '11',
          label: '筛查员'
        },
        {
          value: '10',
          label: '学校管理员'
        }
      ],
      schoolOptions: [],
      screeningOptions: [],
      startPage: 1,
      pageSize: 1000,
      ruleForm: {
        sysName: '',
        phone: '',
        roleType: 10
      },

      rules: {
        sysName: [
          { required: true, message: '请填写姓名', trigger: 'blur' },
          { min: 1, max: 10, message: '请填写1~10个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请填写手机', trigger: 'blur' },
          { type: 'number', validator: checkPhone, message: '请输入正确的11位手机号', trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  mounted () {
  },
  created () {
  },
  methods: {
    goTo (path, query) {
      this.$router.push({ path: path, query: query })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        // return false
        if (valid) {
          const that = this
          const ruleForm = this.ruleForm
          that.fullscreenLoading = true
          ruleForm.schoolId = storage.get('schoolId')
          sysService.add(ruleForm).then(res => {
            sessionStorage.setItem('accountAddInfo', JSON.stringify({ msg: '账户创建成功', data: res.data }))
            this.$alert(`账号: ${res.data.phone}; 密码: ${res.data.password}`, '请记住你的账号密码', {
              confirmButtonText: '复制账号密码',
              callback: () => {
                var input = document.createElement('input') // 直接构建input
                input.value = '账号：' + res.data.phone + ' 密码：' + res.data.password // 设置内容
                document.body.appendChild(input) // 添加临时实例
                input.select() // 选择实例内容
                document.execCommand('Copy') // 执行复制
                document.body.removeChild(input) // 删除临时实例
                that.$router.push({ name: 'managementStudentSchoolSchoolAdministrator' })
              }
            })
            // this.$message({
            //   message: res.errMsg,
            //   type: 'success',
            //   duration: 1000,
            //   onClose: function () {
            //     that.fullscreenLoading = false
            //     that.$router.push({ name: 'managementStudentSchoolSchoolAdministrator' })
            //   }
            // })
          }).catch(() => {
            that.fullscreenLoading = false
          })
        } else {
          this.$message.error('请填入相应信息！')
          return false
        }
      })
    },
    returnPage () {
      this.$router.push({ name: 'managementStudentSchoolSchoolAdministrator' })
    }
  }

}
</script>
<style lang="scss" scoped>
.account-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 24px 0;
  box-sizing: border-box;
  max-width: 500px;
}
.header-container{
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #f5f5f5;
  .header-section{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .route-name{
      line-height: 43px;
      font-size: 12px;
      color: #333;
    }
    .button-section{
      display: flex;
      align-items: center;
    }
  }
}
.page{
    padding: 20px 10px;
    min-height: 100%;
    background-color: #fff;
  }
  .tipss{
    color: #333;
  }
</style>
